 /* #Text
   -------------------------------------------------------------------------- */
/*doc
---
name: Mincho
category: Component
tag: Text
---

サイト全体で使うテキストスタイルを定義します。

```ejs
<p class="c-text-mincho">ダミーテキストです。</p>
```
*/

.c-text {
    font-size: 1.6rem;
    line-height: 3.0rem;
    letter-spacing: 0.05em;
    &.u-cut {
        &::before {
            content: '';
            display: block;
            margin-top: -0.7rem;
        }
    }
    &.u-cut-un {
        &::after {
            content: '';
            display: block;
            margin-bottom: -0.7rem;
        }
    }
    @include _mq-down(md) {
        font-size: 1.4rem;
        line-height: 2.5rem;
        letter-spacing: 0em;
        &.u-cut {
            &::before {
                content: '';
                display: block;
                margin-top: -0.4rem;
            }
        }
        &.u-cut-un {
            &::after {
                content: '';
                display: block;
                margin-bottom: -0.4rem;
            }
        }
    }
}
.c-text-green {
    color: $color-green;
}

.c-text-mincho {
  font-family: $font-family-serif;
}
.c-text-gothic {
  font-family: $font-family-sans-serif;
}
