// Object - project - tab

.tp-select-wrap {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    position: relative;
    margin-top: 4.7rem;
    @include _mq-down(md) {
        margin-top: 3.2rem;
        flex-wrap: wrap;
    }
}
@include _mq-down(md) {
    .tp-select-spwrap {
        position: relative;
        padding-bottom: 3rem;
    }
}

.tp-select-btn {
    background-color: #e6e6e6;
    padding: 1.5rem 2rem 2rem 2rem;
    position: relative;
    //margin-bottom: calc(428px + 65px);
    cursor: pointer;
    transition: background-color 0.2s ease;
    &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3rem 2.5rem 0 0;
        border-color: #e6e6e6 transparent transparent transparent;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2.9rem;
        margin: 0 auto;
        transition: border-color 0.2s ease;
    }
    @include _hover {
        background-color: #e2f3fc;
        &::after {
            border-color: #e2f3fc transparent transparent transparent;
        }
    }
    &.is-Open {
        background-color: #e2f3fc;
        &::after {
            border-color: #e2f3fc transparent transparent transparent;
        }
    }
}

.tp-select-tit {
    .num {
        display: block;
        color: $color-orange;
        font-size: 6.4rem;
        font-weight: bold;
        text-align: center;
    }
    .title {
        display: block;
        color: $color-brand;
        font-size: 3.0rem;
        font-weight: bold;
        line-height: 4.4rem;
        letter-spacing: .05em;
        text-align: center;
        margin-top: 0.3rem;
        b {
            color: $color-green;
        }
    }
}

.tp-select-img {
    display: block;
    width: 28rem;
    height: 12rem;
    margin-top: 1rem;
}
