 /* #Footer
   -------------------------------------------------------------------------- */
/*.l-footer {}
.l-footer__copryright-area {}
.l-footer__copryright {}
*/
.l-footer {
    margin-top: 50px;
    &__wrap {
        width: 1000px;
        margin: 0 auto;
    }
    &__company {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: -30px;
    }
    &__logo {
        width: 200px;
        margin-left: 30px;
        @include _hover {
            opacity: 0.6;
        }
    }
    &__siteseal {
        width: 130px;
        height: 66px;
        margin-left: 50px;
        @include _hover {
            opacity: 0.6;
        }
    }
    &__adress {
        text-align: center;
    }
    &__copy {
        width: 100%;
        height: 50px;
        font-size: 1.2rem;
        line-height: 1.5;
        text-align: center;
        background: -webkit-gradient(linear,left top,right top,from(#54c2f0),to(#004097));
        background: linear-gradient(to right, #54c2f0, #004097);
        color: #fff;
        letter-spacing: 0.075em;
        margin-top: 50px;
        @include _mq-down(md) {
            height: 100px;
        }
    }
    &__box{
        display:flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        flex-flow: row-reverse;
        @include _mq-down(md) {
            flex-flow: column;
            justify-content: center;
            height: 100px;
            p:nth-child(1){
                margin-bottom: 10px;
            }
        }
    }
    &__linkarea {
        a {
            color: #fff;
            position: relative;
            &::after {
                content: '';
                width: 0%;
                height: 1px;
                background-color: rgba(#fff, 0.7);
                position: absolute;
                left: 0;
                right: 0;
                bottom: -0.3rem;
                margin: 0 auto;
                transition: width 0.2s ease;
            }
        }
        @include _hover {
            a {
                &::after {
                    width: 100%;
                }
            }
        }
    }
}
@include _mq-down(md) {
    .l-footer {
        margin-top: 50px;
        &__wrap {
            width: 100%;
        }
        &__copy {
            height: 100px;
        }
    }
}

@include _mq-down(sm) {
    .l-footer {
        &__logo:nth-child(2){
            margin-top: 20px;
        }
    }
}

@include _mq-up(md) {
    .l-footer.bottom-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}




