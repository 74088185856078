// Object - project - title

.p-section-tit {
    font-family: $font-family-tit;
    color: $color-brand;
    font-size: 3.2rem;
    font-weight: 800;
    text-align: center;
    position: relative;
    padding-bottom: 6.4rem;
    &__sub {
        color: #999;
        display: block;
        font-size: 2.0rem;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin-top: 2.0rem;
    }
    &::after {
        content: '';
        width: 100%;
        height: 3.0rem;
        background: url("../../assets/img/common/tit_unbar_pc.svg") center center / contain no-repeat;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
    }
    &.page {
        padding-bottom: 7.4rem;
    }
    @include _mq-down(md) {
        font-size: 2.4rem;
        padding-bottom: 5.8rem;
        &.page {
            padding-bottom: 5.8rem;
        }
        &__sub {
            font-size: 1.4rem;
            margin-top: 1.8rem;
        }
        &::after {
            height: 2.5rem;
            background: url("../../assets/img/common/tit_unbar_sp.svg") center center / contain no-repeat;
        }
    }
}

.p-gradation-band {
    background-image: $color-gradation;
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    width: 100%;
    padding: 1.3rem 1rem;
    @include _mq-down(md) {
        font-size: 1.8rem;
        line-height: 2.5rem;
        padding: 0.9rem 1rem;
    }
}

.p-yellow-unbar-tit {
    margin-top: 6.0rem;
    color: $color-brand;
    font-size: 3.2rem;
    line-height: 5.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    display: inline;
    background-position: left -100% center;
    padding-bottom: 0.7em;
    background-size: 200% 0.46em;
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgb(255, 255, 0) 50%);
    //transition: background-position 2s ease 0.1s;
    & + .undertxt {
        margin-top: 2.5rem;
    }
    @include _mq-down(md) {
        font-size: 2.0rem;
        line-height: 3.2rem;
        padding-bottom: 0.7em;
        background-size: 200% 0.37em;
        & + .undertxt {
            margin-top: 2.3rem;
        }
    }
}

.p-primary-tit {
    color: $color-brand;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 4.0rem;
    letter-spacing: 0.05em;
    &.u-cut {
        &::before {
            content: '';
            display: block;
            margin-top: -0.8rem;
        }
    }
    @include _mq-down(md) {
        font-size: 1.8rem;
        line-height: 3.0rem;
        &.u-cut {
            &::before {
                margin-top: -0.6rem;
            }
        }
    }
}

.p-secondary-tit {
    color: $color-brand;
    font-size: 2.0rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 3.0rem;
    &.u-cut {
        &::before {
            content: '';
            display: block;
            margin-top: -0.5rem;
        }
    }
    @include _mq-down(md) {
        font-size: 1.6rem;
        line-height: 2.8rem;
        &.u-cut {
            &::before {
                margin-top: -0.6rem;
            }
        }
    }
}
