// Object - project - btn

.p-linkbtn {
    //width: 32rem;
    width: 26rem;
    //height: 5rem;
    background-color: $color-brand;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    transition: background-color 0.4s ease;
    a {
        display: block;
        color: #fff;
        padding: 1.5rem;
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        border: 0.2rem solid $color-brand;
        transition: color 0.4s ease;
        /*&::after {
            content: '';
            width: 1.6rem;
            height: 1.6rem;
            background: url("../../assets/img/common/icon_circle_arrow_w.svg");
            position: absolute;
            right: 1.5rem;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }*/
    }
    &__icon {
        width: 1.6rem;
        height: 1.6rem;
        overflow: visible;
        enable-background: new 0 0 16 16;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1.5rem;
        margin: auto 0;
        fill: #fff;
        transition: fill 0.4s ease;
    }
    @include _hover {
        background-color: #fff;
        a {
            color: $color-brand;
        }
        .p-linkbtn__icon {
            fill: $color-brand;
        }
    }
    @include _mq-down(md) {
        width: 100%;
        max-width: 325px;
        font-size: 15px;
        a {
            padding: 15px;
        }
        &__icon {
            width: 16px;
            height: 16px;
            right: 15px;
        }
    }
}

.p-linkbtn-white {
    width: 32rem;
    //height: 5rem;
    background-color: #fff;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    transition: background-color 0.4s ease;
    a {
        display: block;
        color: $color-brand;
        padding: 1.7rem;
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        transition: color 0.4s ease;
        /*&::after {
            content: '';
            width: 1.6rem;
            height: 1.6rem;
            background: url("../../assets/img/common/icon_circle_arrow_b.svg");
            position: absolute;
            right: 1.5rem;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }*/
    }
    &__icon {
        width: 1.6rem;
        height: 1.6rem;
        overflow: visible;
        enable-background: new 0 0 16 16;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1.5rem;
        margin: auto 0;
        fill: $color-brand;
        transition: fill 0.4s ease;
    }
    @include _hover {
        background-color: $color-brand;
        a {
            color: #fff;
        }
        .p-linkbtn-white__icon {
            fill: #fff;
        }
    }
}

.p-morebtn {
    width: 26rem;
    //height: 5rem;
    background-color: $color-brand;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    span {
        display: block;
        color: #fff;
        padding: 1.7rem;
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        &::after {
            content: '';
            width: 1.6rem;
            height: 1.6rem;
            background: url("../../assets/img/common/icon_circle_arrow_w.svg");
            position: absolute;
            right: 1.5rem;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }
    }
    & + .tp-vision-list {
        display: none;
    }
}

.p-dlbtn {
    //width: 50.2rem;
    width: 40rem;
    height: 5.2rem;
    border: 0.2rem solid $color-brand;
    border-radius: 2.6rem;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    transition: background-color 0.4s ease;
    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-brand;
        width: 100%;
        height: 100%;
        text-align: center;
    }
    &__txt {
        position: relative;
        padding-right: 2.4rem;
        padding-left: 1.1rem;
        transition: color 0.4s ease;
        /*&::after {
            content: '';
            width: 2.4rem;
            height: 2.2rem;
            background: url("../../assets/img/common/icon_download_b.svg");
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            transition: 0.4s ease;
        }*/
    }
    &__icon {
        width: 2.4rem;
        height: 2.03rem;
        fill: $color-brand;
        transition: fill 0.4s ease;
    }
    @include _hover {
        background-color: $color-brand;
        .p-dlbtn__txt {
            color: #fff;
            /*&::after {
                background: url("../../assets/img/common/icon_download_w.svg");
            }*/
        }
        .p-dlbtn__icon {
            fill: #fff;
        }
    }
    @include _mq-down(md) {
        width: 325px;
        height: 52px;
        font-size: 15px;
        letter-spacing: 0.025em;
        border-radius: 26px;
        &__link {
        }
        &__txt {
            padding-right: 20px;
        }
        &__icon {
            width: 24px;
            height: 20.3px;
        }
    }
}

