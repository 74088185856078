// Object - project - news

.p-news-post {
    border-bottom: 1px solid #000;
    font-size: 1.8rem;
    letter-spacing: 0.07em;
    display: flex;
    align-items: center;
    padding: 1.85rem 0;
    .date {
        color: $color-brand;
        margin-top: 0.3rem;
    }
    @include _mq-up(md) {
        .tit {
            line-height: 1em;
            height: 1em;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            margin-left: 5.0rem;
        }
    }
    @include _mq-down(md) {
        display: block;
        font-size: 14px;
        padding: 12px 1px 11px;
        .tit {
            margin-top: 1px;
            line-height: 22px;
        }
    }
}
