// ==========================================================
// 	Layout - main
// ==========================================================
/*
@font-face {
    src: url("../../assets/doc/fonts/NotoSansJP-Regular.woff") format("woff");
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    src: url("../../assets/doc/fonts/NotoSansJP-Bold.woff") format("woff");
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
*/
html {
    font-size: 0.7321vw;
    @include _mq-down(md) {
        font-size: 2.6667vw;
    }
}

.u-display {
    &-sm {
        @include _mq-up(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-up(md) {
            display: none !important;
        }
    }
}

.u-hide {
    &-sm {
        @include _mq-down(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: none !important;
        }
    }
}

.u-d-block {
    display: block !important;
}

.u-d-inblock {
    display: inline-block !important;
}

.u-hankaku {
    font-family: YakuHanJP, $font-family-sans-serif;
}

.u-txt-center {
    text-align: center;
}
@include _mq-up(md) {
    .u-txt-center-pc {
        text-align: center;
    }
}
@include _mq-down(md) {
    .u-txt-center-sp {
        text-align: center;
    }
}

.u-fl-right {
    float: right;
}
.u-fl-left {
    float: left;
}
@include _mq-up(md) {
    .u-fl-right-pc {
        float: right;
    }
    .u-fl-left-pc {
        float: left;
    }
}

@include _mq-up(md) { //PC時telリンク機能停止
    a[href*="tel:"] {
        text-decoration: none;
        cursor: default;
        pointer-events: none
    }
}

.txt-link-ubar {
    color: $color-brand;
    text-decoration: underline;
    @include _hover {
        opacity: 1;
        text-decoration: none;
    }
}

/*object-fit-image*//*imgタグにクラス付与*/
.obj-fit-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover';
}
