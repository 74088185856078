 /* #Header
   -------------------------------------------------------------------------- */
/*.l-header {}
.l-header__logo-area {}
.l-header__logo {}
.l-header__nav-area {}
.l-header__nav {}
.l-header__nav-item {}
.l-header__nav-link {}
.l-header__background{}
*/

/*ヘッダー*/
.l-header {
    width: 100%;
    height: 7.0rem;
    &__wrap {
        width: 100%;
        background: #fff;
        height: 7.0rem;
        position: fixed;
        z-index: 999;
    }
    @include _mq-down(md) {
        height: 70px;
        &__wrap {
            height: 70px;
            border-bottom: 1px solid #0075c1;
        }
        &__tit {
            font-size: 14px;
            font-weight: bold;
            line-height: 24px;
            padding: 10px 0 10px 24px;
        }
    }
}

/*PCナビゲーション*/
.l-gnav {
    width: 120rem;
    margin-right: auto;
    margin-left: auto;
    height: 7.0rem;
    padding: 2.7rem 0 2.8rem;
}
.l-gnav > ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    border-right: 1px solid #000;
}
.l-gnav > ul > li {
    padding: 0 1.9rem;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    position: relative;
    border-left: 1px solid #000;
    &.isCurrent {
        a {
            color: $color-brand;
        }
    }
}
.l-gnav > ul > li > a {
    position: relative;
    transition: color 0.4s ease;
    &::after {
        content: '';
        width: 0%;
        height: 1px;
        background: $color-brand;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.2rem;
        margin: 0 auto;
        transition: width 0.4s ease
    }
    @include _hover {
        color: $color-brand;
        &::after {
            width: 100%;
        }
    }
}
@include _mq-down(md) {
    .l-gnav-toggle-area {
        width: 70px;
        height: 70px;
        background-color: #0075c1;
        border: 0;
        padding: 0;
        z-index: 9999;
        display: block;
        position: fixed;
        right: 0;
        top: 0;
        cursor: pointer;
    }
    .l-gnav-toggle {
        width: 29px;
        height: 20px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 25px;
        > span {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 1px;
            background-color: #fff;
            transition: 0.2s $ease-out-cubic;
            transform-origin: center;
        }
        > span:nth-child(1) {
            top: 0px;
        }
        > span:nth-child(2) {
            top: 9px;
        }
        > span:nth-child(3) {
            top: 18px;
        }
    }
    .l-gnav-menu-area {
        font-size: 15px;
        position: fixed;
        z-index: 9997;
        top: 71px;
        right: 0;
        width: 100%;
        height: calc(100vh - 70px);
        transform: translate3d(105%, 0, 0);
        transition: 0.4s $ease-out-cubic;
        background-color: #fff;
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
@include _mq-down(sm) {
    .l-gnav-menu-area {
        height: auto;
    }
}
.l-gnav-background {
    /*visibility: visible;*/
    background: #fff;
    width: 0;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 70px;
    z-index: 9996;
    transition: 0.4s $ease-out-cubic;
}

/*メニューボタンをクリックした時にis-drawer-openを付与*/
.is-drawer-open {
    .l-gnav-menu-area {
        transform: translate3d(0, 0, 0);
    }
    .l-gnav-toggle {
        > span:nth-child(1) {
            transform: translate3d(0, 9px, 0) rotate(35deg);
        }
        > span:nth-child(2) {
            transform: scale(0);
            opacity: 0;
        }
        > span:nth-child(3) {
            transform: translate3d(0, -9px, 0) rotate(-35deg);
        }
    }
    .l-gnav-background {
        /*visibility: visible;*/
        width: 100%;
    }
}

@include _mq-down(md) {
    .l-gnav-menu {
        li {
            position: relative;
            padding-right: 20px;
            padding-left: 24px;
            border-bottom: solid 1px #0075c1;
            width: 100%;
            z-index: 9998;
            > a, span {
                height: 50px;
                line-height: 50px;
                color: #000;
            }
        }
        &__bnr {
            width: 325px;
            border: 1px solid #666;
            margin: 30px auto;
        }
    }
    .l-gnav-sns {
        display: flex;
        justify-content: center;
        column-gap: 42px;
        z-index: 11;
        margin-top: 29px;
        margin-bottom: 40px;
        .item {
            width: 42px;
            height: 42px;
        }
    }
}
