// Object - project - office

.p-office {
    background-color: #def1fb;
    padding-top: 11rem;
    padding-bottom: 7rem;
    &__btn {
        margin: 7.0rem auto 0;
    }
    @include _mq-down(md) {
        padding-top: 5rem;
        padding-bottom: 5rem;
        &__btn {
            margin: 3.5rem auto 0;
        }
    }
}

.p-office-tit {
    font-size: 2.2rem;
    font-weight: bold;
    text-align: center;
    @include _mq-down(md) {
        font-size: 18px;
        width: 256px;
    }
    display: inline;
    padding-bottom: .9em;
    padding-right: .2em;
    padding-left: .2em;
    letter-spacing: .05em;
    background-image: linear-gradient(to right,rgba(255,255,255,0) 50%,#fff 50%);
    background-repeat: repeat-x;
    background-position: left -100% center;
    background-size: 200% .46em;
}

.p-office-card {
    margin-top: 5.0rem;
    .p-gradation-band.left {
        width: 47.5rem;
        float: left;
    }
    &__item {
        margin-top: 3.0rem;
        float: left;
    }
    &__subtit {
        color: $color-brand;
        font-size: 1.7rem;
        font-weight: bold;
        background-color: #fff;
        width: 12rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        float: left;
    }
    &__txt {
        padding-left: 2.0rem;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 3.2rem;
        letter-spacing: 0.1em;
        float: left;
        &::before {
            content: '';
            display: block;
            margin-top: -0.5rem;
        }
        .phone {
            display: block;
            margin-top: 0.5rem;
            font-size: 3rem;
            letter-spacing: 0.05em;
        }
    }
    &__map {
        float: right;
        iframe {
            width: 47.5rem;
            height: 30.7rem;
        }
    }
    @include _mq-down(md) {
        .p-gradation-band.left {
            width: 100%;
            float: none;
        }
        &__subtit {
            font-size: 1.5rem;
            width: 32.5rem;
            height: 3.0rem;
            border-radius: 0.5rem;
            float: none;
        }
        &__txt {
            padding-left: 0;
            font-size: 1.6rem;
            line-height: 2.8rem;
            float: none;
            text-align: center;
            margin-top: 1.5rem;
            &::before {
                content: '';
                display: block;
                margin-top: -0.6rem;
            }
            .phone {
                display: block;
                margin-top: 0.5rem;
                margin-bottom: 0.3rem;
                font-size: 2.5rem;
                letter-spacing: 0.05em;
            }
        }
        &__map {
            float: none;
            margin-top: 2rem;
            iframe {
                width: 32.5rem;
                height: 24.0rem;
            }
        }
    }
}
