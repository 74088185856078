// ==========================================================
//  Layout - main
// ==========================================================
/*.l-wrap {
    @include _wrapper($wrapper-width);
}
.l-wrap-wide {
    @include _wrapper($wrapper-width-wide);
}
.l-wrap-max {
    @include _wrapper-fluid($wrapper-width-max);
}
.l-wrap-fluid {
    @include _wrapper-fluid($wrapper-width);
}*/

.l-wrap {
    width: 100rem;
    margin-right: auto;
    margin-left: auto;
    @include _mq-down(md) {
        width: 100%;
        padding-right: 2.5rem;
        padding-left: 2.5rem;
    }
}
.l-wrap-fluid {
    width: 100rem;
    margin-right: auto;
    margin-left: auto;
    @include _mq-down(md) {
        width: 100%;
    }
}

.l-page {
    width: 100rem;
    margin-right: auto;
    margin-left: auto;
    padding: 9.5rem 0 15rem;
    @include _mq-down(md) {
        width: 100%;
        padding: 6.5rem 2.5rem 6.5rem;
    }
}

.l-post {
    width: 100rem;
    margin-right: auto;
    margin-left: auto;
    padding: 9.5rem 0 0;
}
