// Object - project -

.top-news-link-box {
    width: 260px;
    margin: 60px auto 0;
    @include _mq-down(md) {
        margin-top: 40px;
    }
}
/*
.link-btn {
    width: 260px;
    height: 50px;
    background-color: $color-brand;
    font-size: 1.6rem;
    & a {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
    }
}
*/
.white-circle--brand-arrow {
    &.inner-txt { //文字の後ろに矢印
        position: relative;
    }
    &.innner-box { //BOXの後ろに矢印
        position: absolute;
        right: 20px;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        width: 16px;
        height: 16px;
        margin: auto 0;
        border-radius: 50%;
        vertical-align: middle;
        background: #fff;
    }
    &::after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: 0;
        right: 5px;
        margin: auto 0;
        width: 7px;
        height: 7px;
        margin: auto 0;
        border-top: 2px solid $color-brand;
        border-right: 2px solid $color-brand;
        vertical-align: middle;
        transform: rotate(45deg) scale(0.857);
    }

}
